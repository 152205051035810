<template>
  <v-container>
    <v-layout justify-center align-center>
      <v-flex xs12 sm8 md8>
        <v-sheet color="white" elevation="0" width="100%">
          <v-card flat tile v-if="this.pdfdata.header.doc_code">
            <v-card-text>
              <v-row>
                <v-col cols="12" md="6" class="text-md-left text-center">
                  <table width="100%">
                    <tr>
                      <td>
                        <h3>{{ pdfdata.store.name }}</h3>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ pdfdata.store.address }}</td>
                    </tr>
                    <tr>
                      <td>{{ pdfdata.store.city + ", " + pdfdata.store.province }}</td>
                    </tr>
                    <tr>
                      <td>{{ pdfdata.store.mobile }}</td>
                    </tr>
                    <tr>
                      <td>Orden No. {{ pdfdata.header.doc_code }}</td>
                    </tr>
                  </table>
                </v-col>
                <v-col cols="12" md="6">
                  <table>
                    <tr>
                      <td>Nombre:</td>
                      <td>{{ pdfdata.header.party_name }}</td>
                    </tr>
                    <tr>
                      <td>Dirección :</td>
                      <td>{{ pdfdata.header.party_address }}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        {{
                          pdfdata.header.party_city + ", " + pdfdata.header.party_province
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Email :</td>
                      <td>{{ pdfdata.header.party_email }}</td>
                    </tr>
                    <tr>
                      <td>Móvil :</td>
                      <td>{{ pdfdata.header.party_phone }}</td>
                    </tr>
                  </table>
                </v-col>
                <v-col cols="12">
                  Para entregar :
                  {{
                    pdfdata.header.delivery_date + "  @" + pdfdata.header.delivery_time
                  }}
                </v-col>
              </v-row>
              <!-- <v-data-table :headers="header" :items="pdfdata.items" :items-per-page="-1" hide-default-footer
                sort-by="name" mobile-breakpoint="0" class="table-cursor elevation-1 pt-3" loading-text="Cargando ..."
                dense>
                <template v-slot:top></template>
                <template v-slot:[`item.product_name`]="{ item }" class="pa-0 ma-0">
                  <span class="text-uppercase">{{ item.product_name }} {{ "Pack: " + (item.pack) + " " +
                      item.uom
                  }}</span>
                  <template v-if="item.product_options">
                    <v-row>
                      <v-col cols="12" v-for="(value, key) in item.product_options" :key="key" justify="center">
                        <v-data-table :headers="headerSelected" hide-default-header hide-default-footer
                          :items-per-page="-1" mobile-breakpoint="0" :items="
                            item.product_options[key].filter(function (rg) {
                              return rg.selected === true;
                            })
                          " dense>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </template>
                </template>
                <template v-slot:[`item.tItem`]="{ item }" class="pa-0 ma-0">
                  <span>{{
                      parseFloat(item.tItem).toLocaleString()
                  }}
                  </span>
                </template>
                <template slot="body.append">
                  <tr>
                    <th>Totales</th>
                    <th class="text-center">
                      {{ sumField(pdfdata.items, "qty").toLocaleString(2) }}
                    </th>
                    <th class="text-right">
                      {{ sumField(pdfdata.items, "tItem").toLocaleString(2) }}
                    </th>
                  </tr>
                </template>
              </v-data-table> -->

              <v-data-table
                calculate-widths
                :headers="header[$vuetify.breakpoint.xsOnly]"
                :items="pdfdata.items"
                :items-per-page="-1"
                item-key="index"
                hide-default-footer
                sort-by="name"
                mobile-breakpoint="0"
                class="table-cursor elevation-1"
                loading-text="Cargando ..."
              >
                <template v-slot:top></template>
                <template v-slot:[`item.product_name`]="{ item }">
                  <span>
                    <b class="text-uppercase">{{ item.product_name }}</b> -
                    {{
                      (parseFloat(item.pack) * item.size).toLocaleString(2) +
                      " " +
                      item.uom
                    }}
                    <br />
                    <small v-if="item.shipping_time > 1">
                      Tiempo de entrega: {{ item.shipping_time }} días hábiles.
                    </small>

                    <span v-html="item.note" />
                  </span>
                </template>
                <template v-slot:[`item.pack`]="{ item }">
                  {{
                    (parseFloat(item.pack) * item.size).toLocaleString(2) + " " + item.uom
                  }}
                </template>
                <template v-slot:[`item.qty`]="{ item }">
                  {{ parseFloat(item.qty) }}
                  <!-- <v-btn text fab @click="editItm(item)">
                   
                  </v-btn> -->
                </template>
                <template v-slot:[`item.tax_base`]="{ item }" class="text-right">
                  {{
                    "$" +
                    (
                      parseFloat(item.tax_base) *
                      parseFloat(item.qty) *
                      parseFloat(item.pack)
                    ).toLocaleString()
                  }}
                </template>
                <!-- <template v-slot:[`item.tax`]="{ item }" class="text-right">
                  {{ parseFloat(item.tax).toLocaleString() + "%" }}
                </template> -->
                <template v-slot:[`item.tax_value`]="{ item }" class="text-right">
                  {{
                    "$" +
                    (
                      parseFloat(item.tax_value) *
                      parseFloat(item.qty) *
                      parseFloat(item.pack)
                    ).toLocaleString()
                  }}
                </template>
                <template v-slot:[`item.tItem`]="{ item }" class="text-right">
                  {{ "$" + parseFloat(item.tItem) }}
                </template>

                <template slot="body.append">
                  <tr>
                    <th>
                      <span v-if="!$vuetify.breakpoint.xsOnly">Totales</span>
                    </th>
                    <th class="text-right">
                      {{ this.totals.qty.toLocaleString() }}
                    </th>

                    <th class="text-right" v-if="!$vuetify.breakpoint.xsOnly">
                      {{ this.totals.subtotal.toLocaleString() }}
                    </th>
                    <th class="text-right" v-if="!$vuetify.breakpoint.xsOnly">
                      {{ this.totals.tax.toLocaleString() }}
                    </th>
                    <th class="text-right">
                      {{ this.totals.total.toLocaleString() }}
                    </th>
                  </tr>
                </template>
              </v-data-table>

              <v-data-table
                :headers="header_payments"
                :items="pdfdata.payments"
                :items-per-page="-1"
                item-key="code"
                v-if="paid"
                hide-default-footer
                sort-by="name"
                mobile-breakpoint="0"
                class="table-cursor mt-3"
                loading-text="Cargando ..."
              >
                <template v-slot:top></template>
                <template v-slot:[`item.payment_type`]="{ item }" class="pa-0 ma-0">
                  <b class="text-uppercase">{{ item.payment_type }}</b
                  ><br />
                  <small>{{ item.issue_date }}</small
                  ><br />
                  <small>{{ item.payment_reference }}</small>
                </template>
                <template v-slot:[`item.payment_value`]="{ item }" class="pa-0 ma-0">
                  <span>
                    {{ parseFloat(item.payment_value).toLocaleString() }}
                  </span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <v-alert v-model="notResponse" border="right" v-else>
            Documento no exite ...<br />
            <v-btn text to="/">Regresar</v-btn>
          </v-alert>
        </v-sheet>
      </v-flex>

      <v-dialog v-model="loading_status" hide-overlay persistent max-width="480">
        <v-card color="primary" dark>
          <v-card-text>
            Cargando ...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-bottom-navigation app grow background-color="#064c72" dark v-if="paid">
        <v-btn light to="MyOrders">Regresar</v-btn>
        <v-spacer></v-spacer>
      </v-bottom-navigation>

      <v-bottom-navigation light app v-else>
        <v-btn text to="/MyOrders"> Regresar </v-btn>
        <v-btn text @click="print_pdf()">Descargar PDF</v-btn>
        <v-btn text @click="checkout_wompi">Pagar Ahora</v-btn>
      </v-bottom-navigation>
    </v-layout>
  </v-container>
</template>
<script type="text/javascript" src="https://checkout.wompi.co/widget.js"></script>
<script>
import { webserver } from "../../services/webserver.js";
import createParty from "../../utils/createParty.js";
import createClient from "../../utils/createParty.js";
import createWebset from "../../utils/createSetting.js";
import createWompi from "../../utils/wompi.js";
import docs_lst from "../../utils/docs.js";
import createDoc from "../../utils/createDoc.js";
import jsPDF from "jspdf";
export default {
  beforeMount() {
    // var strn = this.$route.query.id.split("_");
    // if (strn[0]) {
    //   this.pulishableKey = process.env["VUE_APP_" + strn[0]];
    // }
  },
  components: {},
  data() {
    return {
      doc: createDoc(),
      totals: { qty: 0, total: 0, subtotal: 0, tax: 0, paid: 0, unpaid: 0 },
      id_lst: [
        { code: "13", name: "Cédula de ciudadanía" },
        { code: "31", name: "NIT" },
        { code: "11", name: "Registro civil" },
        { code: "12", name: "Tarjeta de identidad" },
        { code: "21", name: "Tarjeta de extranjería" },
        { code: "22", name: "Cédula de extranjería" },
        { code: "41", name: "Pasaporte" },
        { code: "42", name: "Documento de identificación extranjero" },
        { code: "50", name: "NIT de otro país" },
      ],
      docType: docs_lst().docType,
      menu: [
        {
          id: 1,
          name: "Hacer pedido",
          description: "Creear, editar o eliminar productos de la lista de precios.",
          icon: "mdi-store-outline",
          color: "warning",
          to: "../Inicio",
        },
        {
          id: 2,
          name: "Historico de Pedidos",
          description: "Creear, editar o eliminar clientes.",
          icon: "mdi-file-document-edit-outline",
          to: "../MyOrders",
          color: "warning",
        },
      ],
      test: true,
      loading_status: false,
      clicked: false,
      valid: false,
      client_name: "",
      shipping_address: "",
      cardholder: "",
      nameRules: [
        (v) => !!v || "El nombre es requerido",
        (v) => v.length <= 45 || "El nombre debe tener máximo 45 caracteres",
      ],
      emailRules: [
        (v) => !!v || "Correo electrónico es requerido",
        (v) => /.+@.+/.test(v) || "Debe ser un correo electrónico válido.",
      ],
      pulishableKey: "",
      store: createParty(),
      strp: {
        name: "",
        token: "",
        email: "",
        currency_code: "cop",
        amount: 0,
        item_name: "",
        item_number: "",
      },
      pdfdata: { header: { doc_code: null }, store: {}, items: [] },
      pdfbtn: false,
      paid: false,
      header: {
        true: [
          {
            text: "Descripción",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
            width: "60%",
          },
          {
            text: "Qty",
            align: "end",
            value: "qty",
            width: "2",
          },
          {
            text: "Valor",
            align: "end",
            sortable: true,
            value: "tItem",
          },
        ],
        false: [
          {
            text: "Descripción",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
          },
          {
            text: "Cant.",
            align: "end",
            value: "qty",
            width: "2",
          },

          {
            text: "Precio",
            align: "end",
            sortable: true,
            value: "tax_base",
          },

          {
            text: "Impuesto",
            align: "end",
            sortable: true,
            value: "tax_value",
          },
          {
            text: "Total",
            align: "end",
            sortable: true,
            value: "tItem",
          },
        ],
      },
      header_payments: [
        {
          text: "Forma de pago",
          align: "start",
          sortable: true,
          value: "payment_type",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "payment_value",
        },
      ],
      epaycoData: {
        name: null,
        description: null,
        invoice: null,
        currency: "COP",
        amount: 0,
        tax_base: 0,
        tax: 0,
        country: "CO",
        lang: "es",
        external: "true",
        extra1: null,
        extra2: null,
        extra3: null,
        confirmation: null,
        response: null,
        name_billing: null,
        address_billing: null,
        type_doc_billing: null,
        number_doc_billing: null,
        mobilephone_billing: null,
      },
      settings: createWebset(),
      headerSelected: [
        {
          text: "",
          align: "start",
          value: "product_name",
        },
        {
          text: "",
          align: "end",
          value: "price",
          width: 20,
        },
      ],
      bill: createWompi(),
      notResponse: false,
      docId: null,
      WompiPublicKey: null,
    };
  },
  mounted() {
    this.$store.dispatch("set_menuItems", this.menu);
    this.$store.dispatch("setNavStatus", true);
    //this.$store.dispatch("set_menu", false);
    if (this.$route.query.did) {
      this.docId = this.$route.query.did;
      this.get_doc(this.docId);
    } else {
      this.$router.push("MyOrders");
    }
  },
  methods: {
    sumField(fld, key) {
      var dta = fld;
      return dta.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },
    get_doc(doc_code) {
      this.loading_status = true;
      var qry = {
        table: "docs",
        doc_code: doc_code,
      };
      webserver("get_doc", qry, (data) => {
        console.log("get_doc", data);
        this.set_doc(data);
        if (data.header) {
          this.pdfdata = data;
          var item_name = "";
          if (data.items.length > 0) {
            item_name = data.items[0].product_name;
          }
          this.store = data.store;
          if (data.store.setting) {
            this.settings = JSON.parse(data.store.setting);
            this.epaycoPublicKey = this.settings.epaycoPK;
            this.WompiPublicKey = this.settings.wompiPK;
          }
          // data.items.forEach((rg) => {
          //   if (rg.product_options) {
          //     rg.product_options = JSON.parse(rg.product_options);
          //   } else rg.options_selected = null;
          //   rg.tItem = parseFloat(rg.tItem);
          // });

          if (data.payments.length === 0) {
            this.paid = false;
            this.epaycoData = {
              name: item_name,
              description: "",
              invoice: data.header.doc_code,
              currency: "COP",
              amount: parseFloat(data.header.total),
              tax_base: 0,
              tax: 0,
              country: "CO",
              lang: "es",
              external: "false",
              extra1: null,
              extra2: null,
              extra3: null,
              confirmation: "https://mayorista.app/ws/epayco/response/",
              response: "https://mayorista.app/ws/epayco/response/",
              name_billing: data.header.party_name,
              address_billing: data.header.party_address,
              type_doc_billing: "cc",
              mobilephone_billing: data.header.party_phone,
              number_doc_billing: data.header.party_identification_id,
              methodsDisable: [],
            };
            this.bill = {
              currency: "COP",
              amountInCents: parseInt(data.header.total * 100),
              reference: data.header.doc_code,
              publicKey: this.WompiPublicKey,
              redirectUrl: "https://mayorista.app/checkout/?did=" + data.header.doc_code, // Opcional
              customerData: {
                // Opcional
                email: data.header.party_email,
                fullName: data.header.party_name,
                phoneNumber: data.header.party_phone,
                phoneNumberPrefix: "+57",
                // legalId: '123456789',
                // legalIdType: 'CC'
              },
              shippingAddress: {
                // Opcional
                addressLine1: data.header.party_address,
                city: data.header.party_city,
                phoneNumber: data.header.party_phone,
                region: "Cundinamarca",
                country: "CO",
              },
            };
            // console.log(this.bill);
          } else {
            this.paid = true;
            this.pdfdata = data;
            this.pdfbtn = true;
          }
        } else {
          this.notResponse = true;
          this.pdfbtn = false;
        }
        this.loading_status = false;
      });
    },
    set_doc(data) {
      console.log("set_doc", data);
      this.pdf = data;
      this.totals = { qty: 0, total: 0, subtotal: 0, tax: 0, paid: 0, unpaid: 0 };
      data.items.forEach((itm) => {
        itm.qty = parseFloat(itm.qty);
        itm.pack = parseFloat(itm.pack);
        itm.cost = parseFloat(itm.cost);
        itm.price = parseFloat(itm.price);
        itm.discount = parseFloat(itm.discount);
        // itm.discount_value = (
        //   (parseFloat(itm.price) * parseFloat(itm.discount)) /
        //   100
        // ).toFixed(2);

        var tax_value =
          parseFloat(itm.tax_value) * parseFloat(itm.qty) * parseFloat(itm.pack);

        itm.tItem =
          (parseFloat(itm.price_amount) + parseFloat(itm.tax_value)) *
          parseFloat(itm.qty);

        this.totals.qty += parseFloat(itm.qty);
        this.totals.subtotal += parseFloat(itm.tItem) - tax_value;
        this.totals.total += parseFloat(itm.tItem);
        this.totals.tax += tax_value;
      });

      this.docId = data.header.doc_code;
      this.issue_date = data.header.issue_date;

      this.doc_type = this.docType.find((o) => o.value === data.header.doc_type);
      console.log(this.doc_type, data.header.doc_type);

      this.doc = {
        account: null,
        store: null,
        userId: data.header.userId,
        agent: null,
        doc_type: data.header.doc_type,
        doc_code: data.header.doc_code,
        issue_date: data.header.issue_date,
        total: parseFloat(data.header.total),
        note: data.header.note,
        pos: data.store,
        party: createClient(),
        items: data.items,
        payments: data.payments,
        delivery_date: data.header.delivery_date,
        delivery_time: data.header.delivery_time,
        status: data.header.status,
      };
      this.doc.party.name = data.header.party_name;
      this.doc.party.type_id = data.header.party_type_id;
      this.doc.party.identification_id = data.header.party_identification_id;
      this.doc.party.address = data.header.party_address;
      this.doc.party.city = data.header.party_city;
      this.doc.party.mobile = data.header.party_phone;
      this.doc.party.email = data.header.party_email;
      this.doc.party.note = data.header.party_note;
      this.loading_status = false;
      //this.updateTotal();
    },
    print_pdf() {
      var doc = this.pdfdata;
      console.log(this.doc);
      if (doc.header) {
        var pdf = new jsPDF({
          orientation: "p",
          unit: "mm",
          format: "letter",
        });
        var LS = 5;
        var y = 20;
        var x = 20;
        pdf.setFontType = "bold";
        pdf.setFontSize(14);
        pdf.text(20, y, "NGM Colombia SAS", "left");
        pdf.setFontType = "normal";
        pdf.setFontSize(8);
        y += LS;
        pdf.text(
          20,
          y,
          this.id_lst.find((item) => item.code == doc.store.type_id).name +
            " " +
            doc.store.identification_id,
          "left"
        );
        y += LS;
        pdf.text(20, y, doc.store.address, "left");
        y += LS;
        pdf.text(20, y, doc.store.city + ", " + doc.store.province, "left");
        y = 48;
        pdf.setFontSize(8);
        pdf.text(
          20,
          y,
          this.docType.find((item) => item.value == doc.header.doc_type).label +
            " : " +
            doc.header.doc_code,
          "left"
        );
        pdf.text(160, y, "Fecha: " + doc.header.issue_date, "left");
        y += LS;
        pdf.text(160, y, "Estado: " + doc.header.status, "left");
        y += LS;
        y += 2;
        pdf.setLineWidth(0.1);
        pdf.setDrawColor(0, 102, 0);
        // pdf.line(20, y, 200, y);
        y += LS;
        y += LS;
        y = 20;
        pdf.setFontSize(14);
        pdf.text(120, y, doc.header.party_name, "left");
        pdf.setFontType = "normal";
        pdf.setFontSize(8);
        y += LS;
        pdf.text(
          120,
          y,
          this.id_lst.find((item) => item.code == doc.header.party_type_id).name +
            " " +
            doc.header.party_identification_id,
          "left"
        );
        y += LS;

        pdf.text(120, y, doc.header.party_address, "left");
        y += LS;
        pdf.text(120, y, doc.header.party_city, "left");
        y += LS;
        pdf.text(
          120,
          y,
          doc.header.party_email + " Tel: " + doc.header.party_phone,
          "left"
        );
        y += LS;
        pdf.text(120, y, doc.header.party_note, "left");
        y += LS;
        y += LS;
        y = 60;
        pdf.setFontSize(10);
        pdf.setFontType = "normal";
        pdf.text(50, y, "Detalle", "center");
        pdf.text(130, y, "Cant.", "center");
        pdf.text(150, y, "Base", "right");
        pdf.text(175, y, "Impuesto", "right");
        pdf.text(200, y, "Valor", "right");
        // y += 2;
        // pdf.line(20, y, 200, y);
        var temY = y;
        pdf.roundedRect(15, temY - 5, 190, 140, 1, 1);
        y += LS;
        doc.items.forEach((row) => {
          pdf.setFontType = "bold";
          pdf.setFontSize(8);
          pdf.text(20, y, row.product_name);
          pdf.setFontType = "normal";
          pdf.text(130, y, row.qty.toLocaleString(), "center");
          pdf.text(
            150,
            y,
            (
              parseFloat(row.tax_base) *
              parseFloat(row.qty) *
              parseFloat(row.pack)
            ).toLocaleString(2),
            "right"
          );
          pdf.text(
            175,
            y,
            (
              parseFloat(row.tax_value) *
              parseFloat(row.qty) *
              parseFloat(row.pack)
            ).toLocaleString(2),
            "right"
          );
          pdf.text(200, y, "$ " + parseFloat(row.tItem).toLocaleString(2), "right");
          y += LS;
          if (row.shipping_time > 1) {
            pdf.text(20, y, "Tiempo de entrega : " + row.shipping_time + " dias hábiles");
            y += LS;
          }

          pdf.text(20, y, row.note);
          y += LS;

          // pdf.setFontSize(6);
          // var dtext = row.description.replace(/<[^>]+>/g, "");
          // let regex = /&(nbsp|amp|quot|lt|gt);/g;
          // dtext = dtext.replace(regex, " ");
          // var splitTitle = pdf.splitTextToSize(dtext, 105);
          // pdf.text(20, y, splitTitle);
          // y += 3 * splitTitle.length - 1;
          //pdf.text(x, y, dtext);
        });
        y = 200;
        temY = y;
        pdf.setFillColor(255, 255, 200);
        pdf.roundedRect(15, y - 2, 190, 20, 1, 1);
        doc.payments.forEach((row) => {
          y += LS;
          x = 20;
          pdf.text(x, y, row.payment_concept, "left");
          x = 120;
          pdf.text(x, y, "$ " + parseFloat(row.payment_value).toLocaleString(2), "right");
          y += LS;
          x = 30;
          if (row.description) {
            var dtext = row.description.replace(/<[^>]+>/g, "");
            var splitTitle = pdf.splitTextToSize(dtext, 150);
            pdf.text(x, y, splitTitle);
            y += LS;
          }
        });
        y = temY;
        y += LS;
        x = 150;
        pdf.setFontType = "bold";
        pdf.text(x, y, "Sub-Total");
        x = 200;
        pdf.text(
          x,
          y,
          "$ " +
            (parseFloat(doc.header.total) - parseFloat(doc.header.tax)).toLocaleString(2),
          "right"
        );
        y += LS;
        x = 150;
        pdf.text(x, y, "Impuesto");
        x = 200;
        pdf.text(x, y, "$ " + parseFloat(doc.header.tax).toLocaleString(2), "right");
        y += LS;
        x = 150;
        pdf.text(x, y, "TOTAL");
        x = 200;
        pdf.text(x, y, "$ " + parseFloat(doc.header.total).toLocaleString(2), "right");
        // y += 2;
        // pdf.line(20, y, 200, y);
        y += LS;
        y += LS;
        temY = y;
        pdf.setFontSize(8);
        var note = pdf.splitTextToSize(
          "El presente documento presta merito ejecutivo y se asemeja a una letra de cambio.",
          85
        );
        pdf.text(115, y, note);
        pdf.text(20, y, "Observaciones");
        note = pdf.splitTextToSize(doc.header.note, 85);
        y += LS;
        pdf.text(20, y, note);
        // pdf.setFontSize(8);
        y += LS;
        y += LS;
        y += LS;
        y += LS;
        // y += 2;
        // pdf.line(40, y, 120, y);
        y += LS;
        pdf.text(115, y, "ACEPTO : " + doc.header.party_name);
        y += LS;
        pdf.text(
          115,
          y,
          this.id_lst.find((item) => item.code == doc.header.party_type_id).name +
            ": " +
            doc.header.party_identification_id
        );
        pdf.roundedRect(112, temY - 5, 93, y - temY + 8, 1, 1);
        pdf.roundedRect(15, temY - 5, 93, y - temY + 8, 1, 1);
        pdf.setTextColor(0, 183, 0);
        var today = new Date();
        pdf.text(10, 100, "Date Printed : " + today.toLocaleString(), null, 90);
        // var out = pdf.output();
        // var url = "data:application/pdf;base64," + btoa(out);
        // this.pdfFile = url;
        //this.pdfFile = pdf.output('datauri');
        pdf.save(doc.header.doc_code + "_" + doc.header.party_name + ".pdf");
      }
    },
    create_pdf() {
      var doc = this.pdfdata;
      if (doc.header) {
        var pdfName = doc.header.doc_code;
        var pdf = new jsPDF({
          orientation: "p",
          unit: "mm",
          format: [80, 200],
        });

        var LS = 5;
        var y = 20;
        var x = 5;
        pdf.setFontType = "bold";
        pdf.setFontSize(10);
        pdf.text(40, y, doc.store.name, "center");
        pdf.setFontType = "normal";
        pdf.setFontSize(8);
        y += LS;
        pdf.text(40, y, doc.store.address, "center");
        y += LS;
        pdf.text(40, y, doc.store.city + ", " + doc.store.province, "center");
        y += LS;
        y += LS;
        pdf.text(5, y, " # " + doc.header.doc_code, "left");
        y += LS;
        pdf.text(5, y, "Fecha: " + doc.header.issue_date, "left");
        y += LS;
        y += 2;
        pdf.line(5, y, 75, y);
        y += LS;
        y += LS;
        pdf.setFontSize(10);
        pdf.text(5, y, doc.header.party_name, "left");
        pdf.setFontType = "normal";
        pdf.setFontSize(8);
        y += LS;
        pdf.text(5, y, doc.header.party_address, "left");
        y += LS;
        pdf.text(5, y, doc.header.party_city, "left");

        y += LS;
        pdf.text(5, y, doc.header.party_email, "left");
        y += LS;
        pdf.text(5, y, doc.header.party_phone, "left");
        y += LS;

        y += LS;
        pdf.setFontSize(8);
        pdf.setFontType = "normal";
        pdf.text(x, y, "Qty");
        x = 40;
        pdf.text(x, y, "Product", "center");
        x = 75;
        pdf.text(x, y, "Price", "right");
        y += 2;
        pdf.line(5, y, 75, y);

        doc.items.forEach((row) => {
          y += LS;
          x = 5;
          pdf.text(x, y, row.qty.toLocaleString(), "center");
          x = 10;
          pdf.text(x, y, row.product_name);
          x = 75;
          pdf.text(
            x,
            y,
            "$ " + parseFloat(row.price).toFixed(2).toLocaleString(2),
            "right"
          );
        });

        y += 2;
        pdf.line(5, y, 75, y);
        y += LS;
        x = 5;
        pdf.setFontType = "bold";
        pdf.text(x, y, "Sub-Total");
        x = 75;
        pdf.text(x, y, doc.header.total.toLocaleString(2), "right");
        y += LS;
        x = 5;
        pdf.text(x, y, "Impuesto");
        x = 75;
        pdf.text(x, y, parseFloat(doc.header.tax).toFixed(2), "right");
        y += LS;
        x = 5;
        pdf.text(x, y, "TOTAL");
        x = 75;
        pdf.text(
          x,
          y,
          (parseFloat(doc.header.total) + parseFloat(doc.header.tax)).toLocaleString(2),
          "right"
        );
        y += 2;
        pdf.line(5, y, 75, y);

        pdf.save(pdfName + ".pdf");
      }
    },
    checkout_wompi() {
      var checkout = new WidgetCheckout(this.bill);
      checkout.open((result) => {
        var transaction = result.transaction;
        console.log("Transaction ID: ", transaction.reference);
        // console.log("Transaction ID: ", transaction.id);
        // console.log("Transaction object: ", transaction.redirectUrl);
        // // this.$forceUpdate();
        // //this.$router.push(transaction.redirectUrl);
        this.get_doc(transaction.reference);
      });
    },
  },
  watch: {},
};
</script>

<style></style>
